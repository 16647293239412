//import { caseStudies } from "../data";
import { filterCaseStudiesBySection } from "../utils";
import awsPartnerEC2WSD from "../assets/svg/aws-partner-ec2-wsd.svg";
import awsPartnerAdvancedTier from "../assets/svg/aws-partner-advanced-tier.svg";
import awsPartnerWAPProgram from "../assets/svg/aws-partner-wap-program.svg";
import awsMarketplace from "../assets/svg/aws-available-in-aws-marketplace.svg";
import Carousel from "../components/sections/Carousel";
import BulletCardFlexList from "../components/common/BulletCard/BulletCardFlexList";

import {
  ComputerDesktopIcon,
  Square3Stack3DIcon,
  ArrowsPointingOutIcon,
  Cog6ToothIcon,
  CloudArrowDownIcon,
  CircleStackIcon,
  CloudArrowUpIcon,
  LightBulbIcon,
  CurrencyDollarIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";

const AWSMarketplaceLink =
  "https://aws.amazon.com/marketplace/pp/prodview-idy27pb2udlwg?sr=0-1&ref_=beagle&applicationId=AWSMPContessa";

const AWS = () => {
  const [selectedCaseStudies, setSelectedCaseStudies] = useState([]);

  const awsServicesValues = [
    {
      icon: ComputerDesktopIcon,
    
      label: "Amazon EC2 for Windows Workloads",
      img: awsPartnerEC2WSD,
      description:
        "Set up and manage your Windows workloads on Amazon EC2 for optimal performance and security.",
    },
    {
      icon: CircleStackIcon,
      label: "Amazon RDS Delivery",
      description:
        "Seamlessly migrate and manage your databases on Amazon RDS, bridging on-premises, EC2, and diverse database platforms for enhanced efficiency.",
    },
    {
      icon: Square3Stack3DIcon,
      label: "Amazon Glue and Lambda Development",
      description:
        "Build serverless ETL/ELT pipelines using AWS Glue and Lambda to reduce costs and improve scalability.",
    },
    {
      icon: ArrowsPointingOutIcon,
      label: "Secure Landing Zones",
      description:
        "Establish a secure foundation for your AWS infrastructure with our secure landing zone services.",
    },
    {
      icon: Cog6ToothIcon,
      label: "Serverless Data Lake",
      description:
        "Design and implement a serverless data lake using AWS S3, Glue, and Athena for data warehousing and analysis.",
    },
    {
      icon: CloudArrowDownIcon,
      label: "Kubernetes Consulting",
      description:
        "Design, deploy, and manage Kubernetes clusters on AWS with our Kubernetes consulting services.",
    },
    {
      icon: ComputerDesktopIcon,
      label: "Cloud-Native Development on AWS",
      description:
        "Build, deploy, and manage cloud-native applications on AWS using modern DevOps tools and methodologies.",
    },
  ];

  const awsOfferingValues = [
    {
      icon: CloudArrowUpIcon,
      img: "",
      img_link: "",
      label: "Cloud Ready: strategic portfolio assessment and migration roadmap",
      description: "Accelerate your Cloud journey by looking holistically at your portfolio of applications and developing a migration roadmap that is both strategic and pragmatic. Many of our clients assess their applications one-by-one or in small groups. They tend to struggle with the pace of Cloud migration and often fail to achieve the desired financial benefits of moving to Cloud because on-prem infrastructure can’t be shut down (we call this the “Double Bubble”). RAVL takes a different approach. We assess your whole portfolio from three lenses: strategic, financial, and technical. We bundle applications into logical groups of applications that maximize migration synergies and minimize the double bubble. You get a thorough understanding of your overall portfolio and a multi-year migration roadmap with clear rationale for bundle sequencing and timing."
    },
    {
      icon: LightBulbIcon,
      img: "",
      img_link: "",
      label: "Beyond the POC: embedding AI in the organization",
      description: "Operationalize AI to capitalize on its tremendous potential while managing the new and evolving risks applicable to your organization. Many companies we know are stuck experimenting with AI (we call it POC purgatory), not capturing real business value, or trying to perfect their AI governance and patterns before moving forward. RAVL takes a pragmatic approach to advancing strategy, governance, operating model, and use cases in parallel. We help unblock you and unlock real returns while implementing the guardrails to accelerate your progress over the long-term."
    },
    {
      icon: CurrencyDollarIcon,
      img: "",
      img_link: "",
      label: "FinOps Services: Accelerate Cloud and Optimize Value",
      description: "Accelerate and realize the full value of your Cloud investments by establishing or maturing your FinOps practice and engineering solutions that deliver real returns. Too often organizations move to Cloud without having visibility into their Cloud spend or the strategies and capabilities to leverage Cloud’s flexibility and make value-driven decisions. RAVL will understand your unique context and where you are in your Cloud journey to tailor FinOps solutions that deliver more than progress on paper."
    },
    {
      icon: MagnifyingGlassIcon,
      img: awsMarketplace,
      img_link: AWSMarketplaceLink,
      label: "RAVL Well-Architected Review",
      description: "Elevate Your AWS Infrastructure with a comprehensive Well-Architected Review and benefit from AWS best practices for designing and operating secure, reliable, efficient, cost-effective, and sustainable systems in the cloud."
    },
  ];

  useEffect(() => {
    const awsCaseStudies = filterCaseStudiesBySection("aws");
    setSelectedCaseStudies(awsCaseStudies);
  }, []);

  return (
    <section className="px-6 py-6 text-center md:px-12 md:py-12">
    <div className="container mx-auto pt-8 px-6 sm:px-12 md:px-24 lg:px-28">
      <div className="my-6">
        <h1 className="text-4xl font-bold mb-6 text-gray-900 sm:text-5xl text-center">
          Our <span className=" text-purple-700">AWS</span> Partnership and
          Services
        </h1>
        <div className="flex flex-row flex-wrap lg:flex-nowrap justify-center gap-4">
          <img src={awsPartnerAdvancedTier} alt="Data & AI Azure" className="w-full max-w-[10rem] lg:max-w-[12rem] h-auto" />
        </div>
        <p className="mt-14">
          RAVL is your trusted partner for AWS consulting services. We
          specialize in helping highly regulated, financial services and
          insurance organizations, optimize their cloud infrastructure to
          achieve their business goals.
        </p>
      </div>
      <div className="my-14">
        <h3 className="text-lg sm:text-xl font-semibold text-purple-700">
          Our AWS Services
        </h3>
        <p className="mt-2">
          At RAVL, we offer a complete range of AWS consulting services to help
          you unlock the full potential of AWS and achieve your business goals.
        </p>
      </div>
      <div className="my-14">
        <BulletCardFlexList values={awsServicesValues} />
      </div>

      <div className="my-14">
        <h3 className="text-lg sm:text-xl font-semibold text-purple-700">
          Our AWS Offerings
        </h3>
      </div>
      <div className="flex flex-col w-full gap-4 lg:gap-6">
        {awsOfferingValues.map((offering, index) => (
          <div
            className={`card text-left max-w-3xl self-center ${index % 2 ? "md:self-end" : "md:self-start"}`}
            key={offering.label}
          >
            <div className="flex items-center"> {/* Ensures vertical centering of all elements */}
              <div className="flex-grow flex flex-col justify-center">
                <div className="flex items-center gap-4">
                  <offering.icon
                    aria-hidden="true"
                    focusable="false"
                    className="w-6 h-6 stroke-purple-500 fill-transparent" // Icon with purple stroke and no fill
                  />
                  <p className="text-base text-purple-600 font-semibold">
                    {offering.label}
                  </p>
                </div>
                <p className="text-sm text-gray-600">
                  {offering.description}
                </p>
              </div>
              {offering.img && offering.img_link && (
                <a href={offering.img_link} target="_blank" rel="noopener noreferrer" style={{ width: "100%" }}>
                  <img src={offering.img} alt={`${offering.label} Image`} className="w-full h-auto" />
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="my-14">
        <h3 className="text-lg sm:text-xl font-semibold text-purple-700">
          Why Choose RAVL?
        </h3>
        <p className="mt-2">
          At RAVL, we have a team of AWS experts with extensive experience in
          working with highly regulated industries. Our focus is on delivering
          customized solutions that meet your specific needs and goals. We are
          committed to providing excellent customer service and support to help
          you achieve success with AWS.
        </p>
      </div>
      <div className="my-14">
        <h3 className="text-lg sm:text-xl font-semibold text-purple-700 mb-2">
          The Work We've Done:
        </h3>
        <Carousel caseStudies={selectedCaseStudies} />
        <div className="my-14">
          <h3 className="text-lg sm:text-xl font-semibold text-purple-700 mt-10">
            Let's Talk!
          </h3>
          <p className="mt-2">
            If you're ready to take your business to the next level with AWS,
            contact us today to schedule a consultation with one of our AWS
            experts. Let us help you unlock the full potential of AWS and
            achieve your business goals with confidence.
          </p>
        </div>
      </div>
    </div>
    </section>
  );
};

export default AWS;
